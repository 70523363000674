@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}
	to {
		background-position: 0 0;
	}
}
@-moz-keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}
	to {
		background-position: 0 0;
	}
}
@-o-keyframes progress-bar-stripes {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 40px 0;
	}
}
@keyframes progress-bar-stripes {
	from {
		background-position: 40px 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes dotty {
	0%, 20% {
		color: rgba(0,0,0,0);
		text-shadow:
				.25em 0 0 rgba(0,0,0,0),
				.5em 0 0 rgba(0,0,0,0);
	}
	40% {
		color: var(--gray);
		text-shadow:
				.25em 0 0 rgba(0,0,0,0),
				.5em 0 0 rgba(0,0,0,0);
	}
	60% {
		text-shadow:
				.25em 0 0 var(--gray),
				.5em 0 0 rgba(0,0,0,0);
	}
	80%, 100% {
		text-shadow:
				.25em 0 0 var(--gray),
				.5em 0 0 var(--gray);
	}
}

@media print {
	@page {
		margin: 2cm .5cm;
	}
	.visible-print {
		display: block !important;
	}
	tr.visible-print {
		display: table-row !important;
	}
	th.visible-print {
		display: table-cell !important;
	}
	td.visible-print {
		display: table-cell !important;
	}
	.hidden-print {
		display: none !important;
	}
	tr.hidden-print {
		display: none !important;
	}
	th.hidden-print {
		display: none !important;
	}
	td.hidden-print {
		display: none !important;
	}
}
a {
	cursor: pointer;
}
img {
	border: 0;
	outline: none;
}
button {
	cursor: pointer;
}
.clickable {
	cursor: pointer;
}
.countryCode {
	width: 30% !important;
}
.phoneNumber {
	width: 62% !important;
}
.inputError {
	border: 1px solid red !important;
}
.selectedItem {
	color: black !important;
}
.dragOverClassObj {
	background-color: darkolivegreen !important;
}
.download {
	position: relative;
	margin-left: 10px;
	margin-top: -5px;
	font-size: 20px;
	cursor: pointer;
}
.hr-text {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: black;
	text-align: center;
	height: 1.5em;
	opacity: .5;
	&:before {
		content: '';
		background: linear-gradient(to right, transparent, #818078, transparent);
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 1px;
	}
	&:after {
		content: attr(data-content);
		position: relative;
		display: inline-block;
		padding: 0 .5em;
		line-height: 1.5em;
		color: #818078;
		background-color: white;
	}
}
.tooltip {
	position: absolute;
	z-index: 1030;
	display: block;
	visibility: visible;
	font-size: 12px;
	line-height: 1.4;
	opacity: 1;
	filter: alpha(opacity=0);
	top: 0;
	max-width: min(200px, 63vw) !important;
}
.tooltip.in {
	opacity: 0.9;
	filter: alpha(opacity=90);
}
.tooltip.top {
	margin-top: -3px;
	padding: 5px 0;
	.tooltip-arrow {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 5px 5px 0;
		border-top-color: #000000;
	}
}
.tooltip.right {
	margin-left: 3px;
	padding: 0 5px;
	.tooltip-arrow {
		top: 50%;
		left: 0;
		margin-top: -5px;
		border-width: 5px 5px 5px 0;
		border-right-color: #000000;
	}
}
.tooltip.bottom {
	margin-top: 3px;
	padding: 5px 0;
	.tooltip-arrow {
		top: 0;
		left: 50%;
		margin-left: -5px;
		border-width: 0 5px 5px;
		border-bottom-color: #000000;
	}
}
.tooltip.left {
	margin-left: -3px;
	padding: 0 5px;
	.tooltip-arrow {
		top: 50%;
		right: 0;
		margin-top: -5px;
		border-width: 5px 0 5px 5px;
		border-left-color: #000000;
	}
}
.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: #ffffff;
	text-align: center;
	text-decoration: none;
	background-color: #000000;
	border-radius: 4px;
}
.tooltip-arrow {
	position: absolute;
	width: 0;
	height: 0;
	border: solid transparent;
}
.tooltip.top-left {
	.tooltip-arrow {
		bottom: 0;
		left: 5px;
		border-width: 5px 5px 0;
		border-top-color: #000000;
	}
}
.tooltip.top-right {
	.tooltip-arrow {
		bottom: 0;
		right: 5px;
		border-width: 5px 5px 0;
		border-top-color: #000000;
	}
}
.tooltip.bottom-left {
	.tooltip-arrow {
		top: 0;
		left: 5px;
		border-width: 0 5px 5px;
		border-bottom-color: #000000;
	}
}
.tooltip.bottom-right {
	.tooltip-arrow {
		top: 0;
		right: 5px;
		border-width: 0 5px 5px;
		border-bottom-color: #000000;
	}
}
.popover {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1010;
	display: none;
	max-width: 276px;
	padding: 1px;
	text-align: left;
	background-color: #ffffff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 6px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	white-space: normal;
	.arrow {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border: solid transparent;
		border-width: 11px;
		&:after {
			position: absolute;
			display: block;
			width: 0;
			height: 0;
			border: solid transparent;
			border-width: 10px;
			content: "";
		}
	}
}
.popover.top {
	margin-top: -10px;
	.arrow {
		left: 50%;
		margin-left: -11px;
		border-bottom-width: 0;
		border-top-color: #999999;
		border-top-color: rgba(0, 0, 0, 0.25);
		bottom: -11px;
		&:after {
			content: " ";
			bottom: 1px;
			margin-left: -10px;
			border-bottom-width: 0;
			border-top-color: #ffffff;
		}
	}
}
.popover.right {
	margin-left: 10px;
	.arrow {
		top: 50%;
		left: -11px;
		margin-top: -11px;
		border-left-width: 0;
		border-right-color: #999999;
		border-right-color: rgba(0, 0, 0, 0.25);
		&:after {
			content: " ";
			left: 1px;
			bottom: -10px;
			border-left-width: 0;
			border-right-color: #ffffff;
		}
	}
}
.popover.bottom {
	margin-top: 10px;
	.arrow {
		left: 50%;
		margin-left: -11px;
		border-top-width: 0;
		border-bottom-color: #999999;
		border-bottom-color: rgba(0, 0, 0, 0.25);
		top: -11px;
		&:after {
			content: " ";
			top: 1px;
			margin-left: -10px;
			border-top-width: 0;
			border-bottom-color: #ffffff;
		}
	}
}
.popover.left {
	margin-left: -10px;
	.arrow {
		top: 50%;
		right: -11px;
		margin-top: -11px;
		border-right-width: 0;
		border-left-color: #999999;
		border-left-color: rgba(0, 0, 0, 0.25);
		&:after {
			content: " ";
			right: 1px;
			border-right-width: 0;
			border-left-color: #ffffff;
			bottom: -10px;
		}
	}
}
.popover-title {
	margin: 0;
	padding: 8px 14px;
	font-size: 14px;
	font-weight: normal;
	line-height: 18px;
	background-color: #f7f7f7;
	border-bottom: 1px solid #ebebeb;
	border-radius: 5px 5px 0 0;
}
.popover-content {
	padding: 9px 14px;
}
.clearfix {
	&:before {
		content: " ";
		display: table;
	}
	&:after {
		content: " ";
		display: table;
		clear: both;
	}
}
.center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.pull-right {
	float: right !important;
}
.pull-left {
	float: left !important;
}
.hide {
	display: none !important;
}
.show {
	display: block !important;
}
.invisible {
	visibility: hidden;
}
.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}
.hidden {
	display: none !important;
	visibility: hidden !important;
}
.affix {
	position: fixed;
}
@-ms-viewport {
	width: device-width;
}
.visible-xs {
	display: none !important;
}
tr.visible-xs {
	display: none !important;
}
th.visible-xs {
	display: none !important;
}
td.visible-xs {
	display: none !important;
}
.visible-sm {
	display: none !important;
}
tr.visible-sm {
	display: none !important;
}
th.visible-sm {
	display: none !important;
}
td.visible-sm {
	display: none !important;
}
.visible-md {
	display: none !important;
}
tr.visible-md {
	display: none !important;
}
th.visible-md {
	display: none !important;
}
td.visible-md {
	display: none !important;
}
.visible-lg {
	display: none !important;
}
tr.visible-lg {
	display: none !important;
}
th.visible-lg {
	display: none !important;
}
td.visible-lg {
	display: none !important;
}
.hidden-xs {
	display: block !important;
}
tr.hidden-xs {
	display: table-row !important;
}
th.hidden-xs {
	display: table-cell !important;
}
td.hidden-xs {
	display: table-cell !important;
}
.hidden-sm {
	display: block !important;
}
tr.hidden-sm {
	display: table-row !important;
}
th.hidden-sm {
	display: table-cell !important;
}
td.hidden-sm {
	display: table-cell !important;
}
.hidden-md {
	display: block !important;
}
tr.hidden-md {
	display: table-row !important;
}
th.hidden-md {
	display: table-cell !important;
}
td.hidden-md {
	display: table-cell !important;
}
.hidden-lg {
	display: block !important;
}
tr.hidden-lg {
	display: table-row !important;
}
th.hidden-lg {
	display: table-cell !important;
}
td.hidden-lg {
	display: table-cell !important;
}
.visible-print {
	display: none !important;
}
tr.visible-print {
	display: none !important;
}
th.visible-print {
	display: none !important;
}
td.visible-print {
	display: none !important;
}
.btn-group {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	>.btn {
		&:first-child {
			&:not(:last-child) {
				&:not(.dropdown-toggle) {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			margin-left: 0;
		}
		position: relative;
		float: left;
	}
	>.btn-group {
		float: left;
	}
}
.btn-group-vertical {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	>.btn {
		position: relative;
		float: left;
	}
}
.btn-group-sm {
	>.btn {
		padding: 5px 10px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 3px;
	}
}
.btn-sm {
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
.btn-default {
	color: #333;
	background-color: #fff;
	border-color: #ccc;
}
.validation-error {
	border: solid 1px #ff234c !important;
}
.btn-support {
	border-radius: 0 !important;
}
.form-error {
	position: absolute;
	z-index: 1030;
	display: block;
	visibility: visible;
	font-size: 12px;
	line-height: 1.4;
	left: 100%;
	width: 255px;
	top: 0;
	margin-left: 20px;
	color: #999;
}
.check-col {
	width: 33.33%;
}
.contactList {
	margin-bottom: 0;
}
.contactListName {
	min-height: 25px !important;
}
.link-select {
	float: right;
}
.recipient-wrap {
	.ready-recipient {
		margin-left: 8px;
	}
}
.focus {
	background: var(--light-gray-at);
	color: #000;
}
.notification {
	height: 0;
	margin-left: -18px;
	margin-right: -18px;
	padding-left: 18px;
	a {
		color: #419ee0 !important;
	}
}
.notification-box {
	border: 1px solid #ccc;
	width: 100%;
	height: 50px;
	border-radius: 4px;
	margin-bottom: 14px;
	box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2),0 3px 10px 0 rgba(0,0,0,0.19) !important;
}
.notification.error {
	background-color: #F5E8EA;
	>.notificationMessage {
		color: #9B202E;
	}
	>.notificationIcon {
		color: #C2272E;
	}
}
.notification.warning {
	background-color: #FDF2EA;
	>.notificationIcon {
		color: #CC6633;
	}
}
.notification.info {
	background-color: #E6F0EB;
	>.notificationIcon {
		color: #006837;
	}
}
.close {
	width: 32px;
	height: 32px;
	opacity: 0.3;
	&:hover {
		opacity: 1;
	}
	&:before {
		position: absolute;
		content: ' ';
		height: 18px;
		width: 2px;
		background-color: #333;
		transform: rotate(45deg);
	}
	&:after {
		position: absolute;
		content: ' ';
		height: 18px;
		width: 2px;
		background-color: #333;
		transform: rotate(-45deg);
	}
}
.notificationMessage {
	padding-top: 20px;
	display: inline-block;
}
.notificationIcon {
	width: 25px;
	display: inline-block;
	font-size: 20px;
}
.notificationClose {
	float: right;
	padding-right: 30px;
	display: inline-block;
	padding-top: 17px;
}
.tooltip.phone {
	margin-top: 36px;
}
.message-box {
	.name {
		width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
.tooltip-box {
	z-index: 2;
}
.textarea-box {
	width: 100%;
	height: 200px;
}
.message-list {
	li {
		height: 53px;
	}
	li.selected {
		a {
			color: #000 !important;
			background: #e2e3e4 !important;
		}
	}
	a {
		color: #808080;
	}
	li.message_events {
		a {
			color: black;
		}
	}
}
.several-cols__row {
	.name {
		width: 70%;
	}
}
.paging {
	text-align: center;
	font-size: 14px;
	margin-left: -6px;
}
.message-tab-content {
	.icon-list {
		li.active {
			&:before {
				background-color: #268519 !important;
				right: 51px;
			}
		}
		li.current {
			&:before {
				background-color: #268519 !important;
				right: 51px;
				background-color: #9a1d2c !important;
				background-color: #9a1d2c !important;
				right: 51px;
				right: 51px;
			}
		}
		.icon-postmark {
			font-size: 33px;
			&:after {
				left: 37px;
				width: 34px;
			}
		}
	}
	.select-wrap {
		max-width: 210px;
	}
	.attached-file {
		img {
			height: 22px !important;
			width: 22px !important;
		}
	}
	.txt-wrap {
		float: left;
	}
}
.green-bg-icon-check-mark {
	background: -webkit-linear-gradient(left, #24991f 50%, #268519 50%, #268519 100%) !important;
	background: linear-gradient(to right, #24991f 50%, #268519 50%, #268519 100%) !important;
}

#loading-bar {
	.peg {
		-moz-box-shadow: #486db5 1px 0 6px 1px;
		-ms-box-shadow: #486db5 1px 0 6px 1px;
		-webkit-box-shadow: #486db5 1px 0 6px 1px;
		box-shadow: #486db5 1px 0 6px 1px;
	}
}
#loading-bar-spinner {
	display: block;
	position: fixed;
	z-index: 10002;
	top: 10px;
	left: 90px;
	.spinner-icon {
		border-top-color: #486db5;
		border-left-color: #486db5;
	}
}
.contacts-heading {
	padding-right: 10px;
}
.confirmEdit {
	cursor: pointer;
	width: 25px;
	height: 20px;
	color: white;
	text-align: center;
	line-height: 20px;
	position: relative;
	top: 5px;
	&:hover {
		background: #7e98cc;
	}
}
.menu {
	list-style: none;
	border-bottom: 0.1em solid black;
	margin-bottom: 2em;
	padding: 0 0 0.5em;
	&:before {
		content: "[";
	}
	&:after {
		content: "]";
	}
	>li {
		display: inline;
		+ {
			li {
				&:before {
					content: "|";
					padding-right: 0.3em;
				}
			}
		}
	}
}
.description {
	text-decoration: line-through;
}
.description.active {
	color: black !important;
	font-weight: bold;
	text-decoration: none;
}
.replyBoxAnchorName {
	color: #808080;
}
.replyBoxAnchorName.event_read {
	color: black;
}
.message-form {
	.row {
		padding-top: 10px;
		padding-bottom: 10px;
	}
	.label-col {
		label {
			margin-bottom: 0;
		}
		padding: 0 15px 0 0;
	}
}
.icon-postmark {
	&:before {
		content: '\2659';
	}
}
.email-title {
	width: 180px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.popup-list {
	a {
		color: #000;
	}
}
.select-wrap {
	margin-top: -8px;
}
.message-block {
	padding-left: 20px !important;
}
.userPicArrow {
	position: relative;
	bottom: 14px;
	color: #666;
	font-size: 19px;
	&:hover {
		cursor: pointer;
	}
}
.circle-title {
	color: black;
}
.uniqCircleTitles {
	position: relative;
	right: 35px;
}
.uniqPieCircles {
	margin-left: 0;
}
.file-logo {
	display: inline-block;
	vertical-align: middle;
	width: 22px;
	height: 22px;
	margin-right: 7px;
}
.contacts-scrollable-list {
	z-index: 2;
	overflow-y: auto;
	overflow-x: auto;
	height: auto;
	max-height: 85vh;
	margin-right: -1px;
}
.advanced-label {
	cursor: pointer;
}
.savePlan {
	margin: 15px 0 29px 55px;
}
.load {
	&:before {
		left: 6px;
	}
}
.autocomplete-items {
	position: inherit;
	content: "";
	color: var(--color-primary);
	font-family: inherit;
	font-size: 13px;
	font-weight: normal;
	span {
		position: inherit;
		content: "";
		color: var(--color-primary);
		font-family: inherit;
		font-size: 13px;
		font-weight: normal;
	}
}
.boldBlack {
	font-weight: bold;
	color: var(--color-primary);
}
.dndPlaceholder {
	display: none;
}
.dndDragover {
	border: 1px dashed #ccc !important;
}
.message-tab-list {
	&:before {
		z-index: -1;
	}
}
.dndDragging {
	opacity: 0.7 !important;
}
.dndDraggingSource {
	display: none;
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-button {
	width: 0;
	height: 0;
}

::-webkit-scrollbar-thumb {
	border: 1px solid rgba(0, 0, 0, 0.5);
	border-radius: 50px;
}

::-webkit-scrollbar-track {
	background: #ffffff;
	border: 1px solid rgba(0,0,0,0.15);
	border-radius: 50px;
	&:hover {
		background: #ffffff;
	}
	&:active {
		background: #ffffff;
	}
}

::-webkit-scrollbar-corner {
	background: transparent;
}

textarea {
	&::-webkit-input-placeholder {
		color: #6a6a6a;
	}
	&:-moz-placeholder {
		color: #6a6a6a;
	}
	&::-moz-placeholder {
		color: #6a6a6a;
	}
}

input {
	&::-webkit-input-placeholder {
		color: #6a6a6a;
	}
	&:-moz-placeholder {
		color: #6a6a6a;
	}
	&::-moz-placeholder {
		color: #6a6a6a;
	}
}

:-ms-input-placeholder {
	color: #6a6a6a;
}

.angular-tooltip {
	font-size: 11px !important;
	max-width: 50% !important;
}
.dropdown.active {
	>i {
		transform: rotate(-90deg);
		-moz-transition: 0.5s;
		-ms-transition: 0.5s;
		-o-transition: 0.5s;
		-webkit-transition: 0.5s;
		transition: 0.5s;
	}
}
.overflowText {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
	>span.zmdi.zmdi-edit {
		color: #888;
		&:hover {
			color: #7e98cc;
		}
	}
	.name-hover {
		display: inline;
	}
}
.highlight {
	color: darkred;
	font-weight: bold;
}

.loader {
	position: absolute;
	background: #818181 !important;
	top: 280px;
	left: calc(100vw / 2 - 16px);
}

.loader:before {
	position: absolute;
	top: 10px;
	left: 10px;
	content: "";
	font-family: "Material-Design-Iconic-Font";
	border: 2px solid #fff;
	border-top: 2px solid #818181;
	border-radius: 50%;
	width: 32px;
	height: 32px;
	-webkit-animation: spin 2s linear infinite;
	-moz-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
}

#notificationSection {
	padding: 5px;
	margin: 5px;
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
	-moz-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
	position: fixed;
	bottom: 50px;
	left: 0;
	right: 0;
	padding: 5px 10px;
	font-size: 11px;
	height: 55px;
	width: 95%;
}

i.fa-question-circle {
	position: relative;
	top: 2px;
	margin: 0 2px;
}

.dropbtn.btn {
	min-width: 0 !important;
	padding: 0;
	position: relative;
	top: 1px;
	z-index: 1;
}
.dropbtn.btn:hover {
	box-shadow: none !important;
}

.recipient-wrap .country-code .dropdown-content {
	left: -5px;
	background-color: var(--bg-primary);
	top: 0;
	transform: translateY(calc(-100% - 7px));
	max-height: 140px;
}

.dropdown-content {
	left: -5px;
	top: 27px;
	max-height: 235px;
	overflow: auto;
}

.checkbox-wrapper {
	position: relative;
	cursor: pointer;
	margin-bottom: 0;
	height: 100%;
	width: 100%;
	display: block;
	input[type="checkbox"] {
		display: none;
		&:checked {
			~ {
				.label-value {
					&:before {
						content: '\f058';
						font-weight: 900;
					}
				}
			}
		}
	}
	.label-value {
		max-width: 100%;
		height: 100%;
		width: 100%;
		&:before {
			font-family: "Font Awesome 5 Free";
			font-style: normal;
			width: 11px;
			height: 11px;
			content: '\f111';
			margin-right: .3em;
			font-weight: 400;
		}
	}
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
}

.input-disabled input {
	opacity: 0.5;
	cursor: not-allowed;
	pointer-events: none;

	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
}

.attachment-wrap {
	font-size: 13px;
	height: unset;
	line-height: 15.6px;
	position: absolute;
	bottom: 40px;
	width: 100%;
	background: var(--bg-secondary);
	-webkit-box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
	box-shadow: 0 0 5px 0 rgba(0,0,0,0.3);
	
	.attachment-items {
		margin-top: 0;
		margin-right: 0;
		position: relative;
		z-index: 2;
		background: var(--bg-secondary);
		
		.attachment-list {
			margin: 0;
			padding: 0;
			max-height: 124px;
			list-style: none;
			overflow-y: auto;
			overflow-x: hidden;
			li {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				padding-right: 5px;
				
				&.finished {
					.item {
						.after {
							background: #009245;
							//width: 240px;
						}
					}
				}
			}
			
			.close {
				margin-left: 14px;
				color: #486db5;
				font-size: 16px;
				
				&:hover {
					color: #7e98cc;
					opacity: 1;
				}
			}
			
			.item {
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-pack: justify;
				-ms-flex-pack: justify;
				justify-content: space-between;
				background: var(--bg-secondary);
				padding: 8px 11px;
				width: 90%;
				
				.after {
					position: absolute;
					content: "";
					bottom: 0;
					left: 0;
					width: 100%;
					height: 3px;
					background: #5fc0e9;
				}
			}
		}
		
		.title {
			display: block;
			margin-bottom: 12px;
		}
	}
	
	.upload-files-counter {
		font-style: italic;
		text-align: center;
		color: var(--gray);
		padding: 5px 0;
	}
	
	.attachment-row {
		-webkit-transition: background .3s ease-in-out, border-color .3s ease-in-out;
		transition: background .3s ease-in-out, border-color .3s ease-in-out;
		background: var(--bg-secondary);
		border: 1px dotted #e2e3e4;
		cursor: pointer;
		margin: 0;
		font-size: 12px;
		padding: 10px 16px 12px 12px;
		height: 35px;
		
		@media (min-width: 768px) {
			font-size: 13px;
			margin: 0;
		}
		
		.file-input-uploader {
			opacity: 0;
			height: 37px;
			position: relative;
			top: -12px;
			left: -15px;
			z-index: 1000;
			width: 100%;
		}
		
		&.drag-over {
			background-color: darkolivegreen;
		}
		
		&:hover {
			background: rgba(204, 204, 204, 0.3) !important;
			border-color: #cccccc !important;
		}
		
		.attachment-popup-wrap {
			position: absolute;
			left: 10px;
			top: 10px;
			display: flex;
			
			.attachment-popup {
				position: relative;
				.opener {
					margin-right: 5px;
				}
				.title {
					font-size: 10px;
					line-height: 20px;
				}

				&.active {
					.opener {
						color: #324c7e;
						
						&:before {
							content: "+";
							font-weight: 500;
							padding-right: 5px;
							font-size: 13px;
							@media (min-width: 768px) {
								font-size: 18px;
								padding-right: 8px;
							}
						}
					}
				}
			}

			.popup {
				position: absolute;
				top: 100%;
				left: 0;
				min-width: 200px;
				border: 1px solid #818181;
				background: var(--bg-primary);
				margin-top: 8px;
				padding: 8px 0;
				z-index: 1;
				box-shadow: 1px 3px 5px 0 rgba(0, 0, 0, .35);
				&.active {
					display: block;
				}
				&:before {
					height: 0;
					width: 0;
					border-bottom: 5px solid var(--bg-primary);
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					position: absolute;
					content: "";
					top: -5px;
					left: 11px;
					z-index: 2;
				}
				&:after {
					height: 0;
					width: 0;
					border-bottom: 6px solid #818181;
					border-left: 6px solid transparent;
					border-right: 6px solid transparent;
					position: absolute;
					content: "";
					top: -6px;
					left: 10px;
				}
				.popup-list {
					margin: 0;
					padding: 0;
					list-style: none;
					a {
						-webkit-transition: color .3s ease-in-out, background .3s ease-in-out;
						transition: color .3s ease-in-out, background .3s ease-in-out;
						display: block;
						padding: 4px 12px;
						color: var(--color-primary);
						&:hover {
							background: rgba(204, 204, 204, 0.3) !important;
							color: #7e98cc;
						}
					}
				}
			}
		}
		
		.title {
			-webkit-transition: color .3s ease-in-out;
			transition: color .3s ease-in-out;
			display: block;
		}
	}
}

.loading-dots:after {
	display: inline-block;
	content: ' .';
	animation: dotty 1s steps(5, end) infinite;
}

.radio-container {
	display: block;
	position: relative;
	padding-left: 18px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	/* Hide the browser's default radio button */
	input {
		position: absolute;
		left: 0;
		opacity: 0;
		cursor: pointer;
		width: fit-content !important;
	}
	/* Create a custom radio button */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 12px;
		width: 12px;
		border-radius: 50%;
		border: 1px solid;
	}

	&:hover input:not(:checked) ~ .checkmark {
		background-color: var(--gray);
	}
	/* Create the indicator (the dot/circle - hidden when not checked) */
	.checkmark:after {
		content: "";
		position: absolute;
		display: none;
	}

	/* Show the indicator (dot/circle) when checked */
	input:checked ~ .checkmark:after {
		display: block;
	}
}

.redborder {
	border-width: 1px;
	border-style: solid;
	background-color: var(--bg-primary);
	padding: 6px 2px;
	width:110px;
	outline: 0;
	cursor: pointer;
	right: 0;
	border-radius: 5px;
	opacity: 0.8;
	font-family: Verdana, Geneva, sans-serif;
	font-weight: 400;
	-webkit-text-size-adjust: 100%;
	&:hover {
		border: 1px solid rgba(0,0,0,0.15);
		font-weight: 600 !important;
		opacity: 1 !important;
	}
}

.warning-action-wrapper {
	margin-top: 10px;
	&.flex {
		margin-top: 5px;
		display: flex;
		justify-content: center;
	}

	.warning-action-btn {
		font-weight: bold;
		border: 1px solid;
		border-radius: 5px;
		padding: 2px;
		&.first {
			margin-right: 5px;
		}
	}
}

.scheduler-applied {
	opacity: 0.2;
	pointer-events: none;
}

.schedule-wrapper {
	display: flex;
	.picker {
		width: 80%;
		position: relative;
		input {
			border-radius: 4px;
			box-sizing: border-box;
			border: 1px solid #ccc;
			padding: 2px 8px;
			z-index: 500;
			height: 30px;
			outline: 0;
			text-indent: 32px;
			width: 100%;
			cursor: pointer;
			&:focus {
				border-color: #ccc;
			}
		}
		.icon {
			position: absolute;
			height: 30px;
			padding: 8px;
			background: var(--bg-secondary);
			border-radius: 4px 0 0 4px;
			box-sizing: border-box;
			border: 1px solid #ccc;
		}
	}
}

// this class is meant to be only a selector for the 'closeToEdgeDropdown' directive which determines dropdown's drop direction
/*.DD-boundary {}

.show-above {
    top: 0;
    transform: translateY(-100%);
}

.show-below {
    top: auto;
}*/

.first-contact-item {
	.dropdown-content {
		max-height: 100px !important;
	}
}

.displayFlowAdvanced .switch-toggle {
	font-size: 28px !important;
}

// fix for flex-gap lack of support in Safari browsers (Desktop and IOS) from 14.1 and below (until 7.1)
/* Safari 10.1 */
@media not all and (min-resolution: 0.001dpcm) {
	@supports (-webkit-appearance:none) and (not (stroke-color:transparent)) {
		@for $i from 1 through 5 {
			.gap-#{$i}.flex-column:not(.flex-wrap), .gap-#{$i}[class^='flxClmn']:not(.flex-wrap) {
				> * {
					margin: calc(5px * #{$i}) 0;

					&:first-child {
						margin-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.gap-#{$i}:not(.flex-column):not([class^='flxClmn']):not(.flex-wrap) {
				> * {
					margin: 0 calc(5px * #{$i});

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
			.gap-#{$i}.flex-wrap {
				> * {
					margin:calc(0.3rem * #{$i});
				}
			}
		}
	}
}
/* Safari 10.1 - 14.1 */
@media not all and (min-resolution: 0.001dpcm) { // from 10.1
	@supports (not (translate: none)) { // until 14.1
		@for $i from 1 through 5 {
			.gap-#{$i}.flex-column:not(.flex-wrap), .gap-#{$i}[class^='flxClmn']:not(.flex-wrap) {
				> * {
					margin: calc(5px * #{$i}) 0;

					&:first-child {
						margin-top: 0;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.gap-#{$i}:not(.flex-column):not([class^='flxClmn']):not(.flex-wrap) {
				> * {
					margin: 0 calc(5px * #{$i});

					&:first-child {
						margin-left: 0;
					}

					&:last-child {
						margin-right: 0;
					}
				}
			}
			.gap-#{$i}.flex-wrap {
				> * {
					margin:calc(0.3rem * #{$i});
				}
			}
		}
	}
}

.thin-scrollbar {
	&::-webkit-scrollbar {
		width: 7px;
	}
}

.autocomplete-drop-menu {
	z-index: 1040;
	width: 100%;
	min-width: 180px;
	top: calc(100% + 5px);
	padding-top: 5px;
	right: 0;
	background: var(--t-bg-primary);
	position: absolute;
	margin: 0;
	border-radius: 4px;
	box-shadow: 0 1px 6px rgba(0, 0, 0, .8);
	transition: all .1s ease-out;

	&:not(.active) {
		visibility: hidden !important;
		opacity: 0 !important;
		transform: translateY(5px) !important;
	}

	&.active {
		visibility: visible !important;
		opacity: 1 !important;
		transform: translateY(0) !important;
	}

	// this is meant to expand the hovering area of the drop-menu so it want close when hovering just between the profile button and this menu
	&:before {
		content: "";
		top: -5px;
		position: absolute;
		width: 100%;
		height: 5px;
		cursor: default;
	}

	ul {
		width: 100%;
		margin: 0 !important;
		padding: 0 !important;
		list-style: none;
		max-height: 300px;
		overflow: auto;

		li {
			text-align: left;
			width: 100%;
			padding: 10px 15px;
			font-size: 12px;
			margin: 0;
			color: var(--t-color-primary);
			border-radius: 4px;
			cursor: pointer;
			outline: none !important;

			&:not(.static-menu-item):hover {
				background: var(--t-light-gray) !important;
			}

			&.focused {
				background: var(--t-bg-secondary);
			}
		}
	}
}

.thin-scrollbar {
	&::-webkit-scrollbar {
		width: 7px;
	}
}

.thinner-scrollbar {
	&::-webkit-scrollbar {
		width: 5px;
	}
}

.radial-progress-bar {
	position: relative;

	.p-bar {
		height: 100%;
		width: 100%;
		border-radius: 50%;
	}

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		background: white;
		width: calc(100% - clamp(7px, 20%, 15px));
		height: calc(100% - clamp(7px, 20%, 15px));
		border-radius: 50%;
		transform: translate(-50%, -50%);
		z-index: 7;
	}

	/*&:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        background: conic-gradient(#757575, white);
        width: calc(calc(100% - clamp(7px, 20%, 15px)) + 2px);
        height: calc(calc(100% - clamp(7px, 20%, 15px)) + 2px);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        z-index: 6;
        animation: radial-progress-spinner 1s linear infinite;
    }*/
}

.dragOverClassObj {
	position: relative;
	&:after {
		position: absolute;
		background-color: rgba(255,255,255,0.8);
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		content: "- Drop file here -";
		z-index: 5000;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 2rem;
		font-family: 'Roboto Condensed';
		letter-spacing: 1px;
		color: grey;
		border: dotted lightgrey;
		pointer-events: none;
	}
}

// interactive hover effects

.hvr-scale:hover {
	transform: scale(1.03) translateZ(0); // trnaslateZ to prevent blur
	transition: transform 0.1s;
	backface-visibility: hidden;   // prevent blur
}

.hvr-opacity:hover {
	opacity: 0.8;
	transition: opacity 0.15s;
}

.hvr-darken:hover {
	filter: brightness(0.5);
	transition: filter 0.15s;
}

.hvr-darken-light:hover {
	filter: brightness(0.98);
	transition: filter 0.15s;
}

.hvr-underline:hover {
	text-decoration: underline !important;
}

.hvr-shadow:hover {
	box-shadow: 0 0 5px lightgrey;
}
//


.app-dropdown {
	$dd-border-radius: 5px;
	border: solid 1px #cccccc;
	border-radius: $dd-border-radius;
	cursor: pointer;
	overflow: hidden;
	position: relative;
	transition: box-shadow 0.1s;

	.app-dropdown-box {
		z-index: 15;
		border-radius: $dd-border-radius;
		transition: all 0.1s;

		> .fa-chevron-down {
			font-size: 0.7rem;
			transition: background 0.15s;
		}

		.search-input {
			margin: 0;
			width: auto;
			max-width: 100%;
			border: 0;
			outline: none;
			border-radius: 5px;
			height: 100%;
			background: var(--bs-primary);
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 25px;
		}

		&:after { // for hover effect when L&F
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: var(--t-color-primary);
			border-radius: 5px;
			opacity: 0;
			transition: all 0.1s;
			z-index: 1;
		}
		> * {
			z-index: 10;
		}
	}

	.app-dropdown-menu-container {
		height: auto;
		width: var(--dropdown-menu-width); // default: 100%. (customable through container)
		// max-height: 300px;
		z-index: 10;
		position: absolute;
		bottom: -2px;
		left: 0;
		right: 0;
		top: auto;
		transform: translateY(100%);
		background: var(--t-bg-primary);
		border: solid 1px #cfcfcf;
		border-top: none;
		border-radius: $dd-border-radius;
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
		box-shadow: 0 1px 2px #bcbcbc;

		visibility: hidden;
		opacity: 0;
		transition: visibility 0.05s, opacity 0.05s;

		virtual-scroller {
			overflow: var(--bs-dropdown-overflow);
			max-height: 320px;
		}

		ul {
			overflow: var(--bs-dropdown-overflow);
			max-height: var(--bs-dropdown-max-height);
			width: 100%;
			margin: 0;
			padding: 0;
			list-style: none;
			li {
				height: auto;
				width: 100%;
				margin: 0;
				padding: 10px;
				max-height: none;
				min-height: 35px;
				border: none;
				text-align: left;
				cursor: pointer;
				transition: all .2s ease-in-out, visibility 0s; // visibility 0 for submenus
				> span { // avoid trustifi-table "ellipsis" rule
					white-space: pre-wrap;
				}
				&.ellipsis {
					span {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				&:hover:not(.static-item):not(.selected):not(.disabled) {
					background: var(--t-bg-secondary);
					color: var(--bs-primary);
					.submenu {
						visibility: visible;
					}
				}
				&.disabled {
					cursor: not-allowed;
					opacity: 0.6;
					&:active {
						pointer-events: none;
					}
				}
				&.static-item {
					background: var(--t-light-gray) !important;
					cursor: default !important;
				}

				// with submenu
				&.has-submenu {
					position: relative;
					padding-right: 22px;

					> ul.submenu {
						position: absolute;
						visibility: hidden;
						background: var(--t-bg-primary);
						opacity: 0;
						width: 100%;
						border-top: solid 1px var(--t-bg-secondary);
						height: auto;
						max-height: none;
						top: 0;
						bottom: auto;
						transition: all .05s ease-out;

						> li { // avoid parent li's hover effect
							color: var(--t-color-primary);
						}

						&, &.drop-left { // default
							left: -1px;
							right: auto;
							transform: translateX(-100%);
							box-shadow: -2px 0 5px #bcbcbc;
						}
						&.drop-right {
							left: auto;
							right: 0;
							box-shadow: 2px 0 5px #bcbcbc;
						}
					}

					&:hover {
						> ul.submenu {
							visibility: visible;
							opacity: 1;
						}
					}

					&:after {
						content: "▾";
						position: absolute;
						right: 10px;
						font-size: 15px;
						transform: scale(1.5, 1);
					}
				}

				.md-checkbox {
					display: inline;

					input[type='checkbox'] {
						height: 0;
						pointer-events: none;
					}
					> label {
						margin: 0;
						font-size: 10.5px;
						vertical-align: middle;
						display: inline-block;

						> span {
							font-size: 13.5px;
						}
					}
				}
			}

		}
	}

	&:hover:not(.active):not(.disabled) {
		box-shadow: 0 1px 3px var(--t-bg-secondary);
		> .app-dropdown-box:not(.bg-primary) {
			background: var(--t-bg-secondary) !important;
		}
		> .app-dropdown-box.bg-primary {
			&:after {
				opacity: 0.15;
			}
		}
	}

	&.active {
		overflow: visible;

		.app-dropdown-menu-container {
			visibility: visible;
			opacity: 1;
		}
		.app-dropdown-menu-container {
			z-index: 1050;
		}

		.app-dropdown-box {
			> .fa-chevron-down {
				transform: rotate(90deg);
			}
		}
	}

	&.disabled {
		cursor: not-allowed;

		.app-dropdown-menu-container {
			visibility: hidden !important;
			opacity: 0 !important;
		}

		.app-dropdown-box {
			pointer-events: none;
			opacity: 0.5;
			&:not(.t-bg-primary) {
				background: var(--t-light-gray);
				opacity: 1;
			}
		}
	}

	&.only-one-item {
		cursor: default;
	}
}

.disabled-overlay {
	position: relative; // change locally if needed
	&:after {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(255, 255, 255, 0.5);
		z-index: 5;
		cursor: not-allowed;
	}
}

btn {
	width: fit-content;
	height: auto;
	display: block;
	min-width: 85px;
	border-radius: 5px;

	&.connected-to-left .simple-btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		box-shadow: none;
	}
	&.connected-to-right .simple-btn{
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		box-shadow: none;
	}
	&.auto-height .simple-btn {
		height: auto;
	}
	&.shrunk {
		min-width: 0;

		.simple-btn {
			height: auto;
			padding: 3px;
		}
	}
}

.loading-dots {
	&:after {
		display: inline-block;
		content: ' .';
		animation: dotty 1s steps(5, end) infinite;
	}
}

app-dropdown {
	&.country-code-dropdown {
		height: unset;

		.app-dropdown {
			border-right: none !important;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
		}

		.app-dropdown-box * {
			text-overflow: unset !important;
		}
	}
}

.compose-country-code {
	.country-code-dropdown, input[type="text"]{
		height: 25px;
	}
}


.drop-menu-container {
	position: relative;

	&:hover {
		.drop-menu:not(.triggered-by-click) {
			visibility: visible;
			opacity: 1;
			transform: translateY(0);
		}
	}

	.drop-menu.triggered-by-click {
		&:not(.active) {
			visibility: hidden !important;
			opacity: 0 !important;
			transform: translateY(5px) !important;
		}

		&.active {
			visibility: visible !important;
			opacity: 1 !important;
			transform: translateY(0) !important;
		}
	}

	.drop-menu {
		z-index: 1040;
		visibility: hidden;
		opacity: 0;
		transform: translateY(5px);
		width: 100%;
		min-width: 180px;
		top: calc(100% + 5px);
		padding-top: 5px;
		right: 0;
		background: var(--t-bg-primary);
		position: absolute;
		margin: 0;
		border-radius: 4px;
		box-shadow: 0 1px 6px rgba(0, 0, 0, .8);
		transition: all .1s ease-out;

		hr {
			margin: 0;
			width: 100%;
			opacity: 0.07;
			position: relative;
		}

		// this is meant to expand the hovering area of the drop-menu so it want close when hovering just between the profile button and this menu
		&:before {
			content: "";
			top: -5px;
			position: absolute;
			width: 100%;
			height: 5px;
			cursor: default;
		}

		// triangle at top
		&:after {
			content: "▲";
			top: -12px;
			transform: scale(1.7, 1.1);
			text-align: right;
			right: 75px;
			position: absolute;
			width: 100%;
			height: 5px;
			cursor: default;
			color: var(--t-bg-primary);
		}

		ul {
			width: 100%;
			margin: 0;
			padding: 0;
			list-style: none;

			li {
				text-align: left;
				width: 100%;
				padding: 13px 15px;
				font-size: 13px;
				margin: 0;
				color: var(--t-color-primary);
				border-radius: 4px;
				transition: background .1s ease-out;

				.fa, .fas, .far, .zmdi {
					font-size: 13px;
					flex-basis: 15px;
					color: var(--t-gray2);
				}

				.fa-external-link-alt {
					position: absolute;
					right: 0;
					font-size: 9px;
					color: slategrey;
				}

				&:not(.static-menu-item):hover {
					background: rgba(204, 204, 204, 0.15) !important;
					color: var(--bs-primary);
				}

				&.expandable {
					position: relative;
					padding-right: 12px;

					.sub-menu {
						visibility: hidden;
						opacity: 0;
						left: -1px;
						right: unset;
						transform: translateX(-100%) translateY(0);
						box-shadow: -1px 1px 3px grey;
						top: 0;
						transition: all .1s ease-out;

						&:after, &:before {
							content: none;
						}
					}

					&:hover {
						.sub-menu {
							visibility: visible;
							opacity: 1;
						}
					}

					&:after {
						content: "▾";
						position: absolute;
						right: 10px;
						font-size: 15px;
						transform: scale(1.5, 1);
					}
				}
			}

			li.static-menu-item {
				background: #fafafa;
				padding: 15px;
				cursor: default;
			}
		}

		&.show-above {
			top: auto;
			bottom: calc(100% + 5px);
			transform: translateY(-5px);

			&:before {
				bottom: -5px;
				top: auto;
			}

			&:after {
				content: "▼";
				bottom: -12px;
				top: auto;
			}
		}

		&.to-left {
			left: 0;
			right: auto;
			// triangle at top
			&:after {
				left: 75px;
				right: auto;
			}
		}

		&.no-triangle:after {
			display: none !important;
			content: none !important;
		}
	}
}

.hide-when-empty:empty {
	display: none;
}

.hide-when-empty-force:empty {
	display: none !important;
}

.edit-contact-modal .country-code-dropdown{
	--dropdown-menu-width: 180px !important;
}
