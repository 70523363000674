@use "sass:math";

@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
.widthAnimation {
	animation: widthAnimation 0.5s ease;
}

@-webkit-keyframes widthAnimation {
	from {
		width: 0;
	}
}

@-moz-keyframes widthAnimation {
	from {
		width: 0;
	}
}

@-o-keyframes widthAnimation {
	from {
		width: 0;
	}
}

@keyframes widthAnimation {
	from {
		width: 0;
	}
}

:root {
	--bg-primary: white;
	--color-primary: black;
	--bg-secondary: #f8f9fa;
	--light-gray: #D3D3D3;
	--light-blue: #f2faff;
	--light-blue2: #f7f6f9;
	--light-gray-at: #e9e9e9ad; //with alpha transparency
	--gray: #808080;
	--gray2: #696969;
	--gray-dark: #282828;

	--primary-color: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));
	--primary-color-h: 220;
	--primary-color-s: 43%;
	--primary-color-l: 50%;
	--primary-color-dark: hsl(var(--primary-color-h),var(--primary-color-s), calc(var(--primary-color-l) - 15%));

	--blue: blue;
	--danger: #dd0d13;
	--warning: darkorange;
	--success: darkgreen;
}
[data-theme="dark"] {
	--bg-primary: rgb(34, 36, 37);
	--bg-secondary: rgb(41, 43, 44);
	--color-primary: white;
	--light-gray: #585858;
	--light-blue: #303233;
	--light-blue2: #292929;
	--light-gray-at: rgba(67, 67, 67, 0.68); //with alpha transparency
	--gray: #A9A9A9;
	--gray2: #BEBEBE;
	--gray-dark: #D7D7D7;
	--primary-color-dark: hsl(var(--primary-color-h), var(--primary-color-s), var(--primary-color-l));;
	--blue: #0093ff;
	--danger: #ff0000;
	--warning: #ffc107;
	--success: #28a745;

	.blue-link:not(.disabled):hover {
		color: var(--blue);
	}
	.md-checkbox{
		label{
			&:before{
				border-color: rgb(255 255 255 / 54%);
			}
		}
	}
	.input-wrap {
		input {
			background-color: var(--bg-primary);
		}
	}
	.dropbtn, .dropbtn:hover {
		background-color: var(--bg-primary)!important;
	}
	.dropdown-content a {
		color: var(--color-primary)!important;
	}

	#newWindowNotificaiton, #newWindowNotificaiton input {
		background-color: var(--bg-primary)!important;
	}

	.brightnessDark {
		filter: brightness(1.5)!important;
	}

	.dropdown-content {
		background-color: var(--bg-secondary)!important;
	}
	.disabled-overlay:after {
		background: #1616169e!important;
	}

	.owl-dt-control-button-content svg {
		fill: black!important;
	}

	.owl-dt-timer-divider:before,
	.owl-dt-timer-divider:after,{
		color: black!important;
	}
}


.country-code .input-group {
	display: inline-flex;
	align-items: center;
	width: 90px !important;
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #ccc;
	background-color: var(--bg-primary);
	position: relative;
	top: 1px;
}

.login-box .country-code  { // a fix for signup page phone input CSS
	.input-group {
		top: 0;
		height: 35px;
		z-index: 1020;
		align-items: center !important;
	}

	.form-control {

	}
}

.country-code .input-group .dropdown {
	width: 30px !important;
	box-shadow: 0px 0px !important;
	outline: none !important;
	background-color: transparent !important;
	color: white !important;
	border: none !important;
	cursor: pointer !important;
	z-index: 10;
}

.country-code .input-group .list-group-item {
	padding: 6px 6px !important;
}

.country-code .input-group .list-group-item:hover {
	background: rgba(204, 204, 204, 0.3) !important;
}

.country-code .list-group.scrollable-menu {
	width: 190px;
	max-height: inherit;
}

.country-code .list-group.scrollable-menu .list-group-item .country-name .dial-code {
	float: right;
	padding-right: 5px;
}

[data-hidden="true"] {
	display: none !important;
}

.sso-button {
	display: inline-flex;
	align-items: center;
	width: 180px;
	height: 35px;
	cursor: pointer;
	border: 1px solid var(--light-gray);
	border-radius: 5px;
	img {
		float: left;
		margin-left: 8px;
		margin-right: 14px;
	}
	span {
		font-weight: 500;
		color: var(--gray2);
		font-size: 10px;
		font-family: 'Roboto', sans-serif;
	}
	b {
		color: var(--gray2);
		font-size: 10px;
		font-family: 'Roboto', sans-serif;
	}
}

.date-time-container-position-a,
.cdk-overlay-pane.owl-dt-popup {
	left: 0 !important;
	//top: 100px !important;
}

.date-time-container-position-b {
	top: 200px !important;
}

.policyCheckbox {
	cursor: pointer;
	input[type="checkbox"] {
		display: none;
		&:checked {
			~ {
				span {
					&:before {
						content: '\f058';
						font-weight: 900;
					}
				}
			}
		}
	}
	span {
		&:before {
			font-family: "Font Awesome 5 Free";
			font-style: normal;
			width: 1em;
			height: 1em;
			content: '\f111';
			margin-right: .3em;
			font-weight: 400;
		}
	}
}


@mixin justification($class) {
	&-center {
		@extend #{$class};
		justify-content: center !important;
	}
	&-start {
		@extend #{$class};
		justify-content: flex-start !important;
	}
	&-end {
		@extend #{$class};
		justify-content: flex-end !important;
	}
	&-between {
		@extend #{$class};
		justify-content: space-between !important;
	}
	&-around {
		@extend #{$class};
		justify-content: space-around !important;
	}
	&-evenly {
		@extend #{$class};
		justify-content: space-evenly !important;
	}
}

.flxRow {
	display: flex !important;
	flex-direction: row;
	@include justification(&);
}

.flxClmn {
	display: flex !important;
	flex-direction: column;
	@include justification(&);
}


// spacing between vertical aligned items (apply this to parent)
// example: vSpace1, .vSpace2
.vSpace {
	@for $i from 1 through 5 {
		&#{$i} {
			> * {
				margin: calc(5px * #{$i}) 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	&05 {
		> * {
			margin: 2.5px 0;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

// spacing between horizontal aligned items (apply this to parent)
// example: .hSpace1, .hSpace2
.hSpace {
	@for $i from 1 through 5 {
		&#{$i} {
			> * {
				margin: 0 calc(5px * #{$i});

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}
	&05 {
		> * {
			margin: 0 2.5px;

			&:first-child {
				margin-left: 0;
			}

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.h100 {
	height:100%;
}

.w100 {
	width:100%;
}

.flex-row, .flex-column, .flex-row-reverse, .flex-column-reverse {
	display: flex !important;
}

.f-1-0 {
	flex: 1 0;
}

// 500 is between bold and normal
.fw-500 {
	font-weight: 500;
}

.centralize {
	justify-content: center;
	align-items: center;
}

.cursor {
	&-pointer {
		cursor: pointer !important;
	}
	&-default {
		cursor: default !important;
	}
}

.fa-google-colorful {
	background: conic-gradient(from -45deg, #ea4335 110deg, #4285f4 90deg 180deg, #34a853 180deg 270deg, #fbbc05 270deg) 73% 55%/150% 150% no-repeat;
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}

.fa-microsoft-colorful {
	background: conic-gradient(#7fbc00 , #7fbc00 90deg, #ffba01 90deg, #ffba01 180deg, #01a6f0 180deg, #01a6f0 270deg, #f34f1c 270deg, #f34f1c);
	-webkit-background-clip: text;
	background-clip: text;
	color: transparent;
	-webkit-text-fill-color: transparent;
}

.fa-yahoo-colorful {
	color: #720e9e !important;
}

.OR-seperator-line {
	position: relative;
	&:before {
		content: "";
		width: 100%;
		border-top: solid 1px;
		opacity: 0.3;
		position: absolute;
	}
	&:after {
		content: "OR";
		background: var(--bg-primary);
		padding: 0 5px;
		position: absolute;
		left: 50%;
		top: 0;
		transform: translate(-50%,-50%);
	}
}

.blue-link {
	color: royalblue !important;
	&:not(.disabled):hover {
		color: darken(royalblue,45%) !important;
	}
	&.disabled {
		color: #818181 !important;
	}
}

.disabled {
	app-dropdown {
		position: relative;
		opacity: 0.6 !important;

		&:after {
			content: "";
			width: 100%;
			position: absolute;
			height: 100%;
			top: 0;
			cursor: not-allowed;
			z-index: 10;
		}
	}

	input[type="text"], input[type="text"], input[type="password"] {
		opacity: 0.6 !important;
		cursor: not-allowed !important;
		pointer-events: none;
		user-select: none;

		&:focus {
			outline: none;
		}
	}
}

a.no-underline {
	text-decoration: none !important;
}

.hover-link-color:hover {
	color: hsl(220 43% 65%) !important;
}
// material-design-like chackbox

$md-checkbox-checked-color: #{var(--bs-primary)};
$md-checkbox-border-color: rgba(0, 0, 0, 0.54);
$md-checkbox-border-color-disabled: rgba(0, 0, 0, 0.26);
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 0;
$md-checkbox-size: 1.25em;
$md-checkbox-padding: .25em;
$md-checkbox-border-width: 2px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.2em;
$md-checkmark-color: #fff;
$md-checkbox-label-padding: 1.75em;
$md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

.md-checkbox {
	position: relative;
	margin: $md-checkbox-margin;
	text-align: left;

	&.md-checkbox-inline {
		display: inline-block;
	}

	label:first-of-type {
		cursor: pointer;
		display: inline;
		position: relative;
		line-height: $md-checkbox-size;
		vertical-align: top;
		clear: both;
		padding-left: 1px;
		&:not(:empty) {
			padding-left: $md-checkbox-label-padding;
		}

		&:before, &:after {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
		}

		&:before {
			// box
			width: $md-checkbox-size;
			height: $md-checkbox-size;
			background: #fff;
			border: $md-checkbox-border-width solid $md-checkbox-border-color;
			border-radius: $md-checkbox-border-radius;
			cursor: pointer;
			transition: background .3s;
		}

		&:after {
			// checkmark
		}
	}

	input[type="checkbox"] {
		outline: 0;
		visibility: hidden;
		width: 0;
		margin: 0;
		display: block;
		float: left;
		font-size: inherit;

		&:checked {
			+ label:before{
				background: $md-checkbox-checked-color !important;
				border:none !important;
			}
			+ label:after {
				transform: translate($md-checkbox-padding, math.div($md-checkbox-size, 2) - math.div($md-checkmark-size, 2.6)) rotate(-45deg);
				width: $md-checkmark-size;
				height: math.div($md-checkmark-size, 2);

				border: $md-checkmark-width solid $md-checkmark-color;
				border-top-style: none;
				border-right-style: none;
			}
		}

		&:disabled {
			+ label:before{
				border-color: $md-checkbox-border-color-disabled;
			}
			&:checked {
				+ label:before{
					background: $md-checkbox-checked-color-disabled;
				}
			}
		}
	}

	// customization:
	// transparent background to box
	&.transparent-box label:before {
		background: transparent;
	}

	&.success-color {
		input[type="checkbox"]:checked + label {
			&:before {
				background: var(--bs-success) !important;
			}
			&:after {
				border-color: white !important;
			}
		}
	}

	&.danger-color {
		input[type="checkbox"]:checked + label {
			&:before {
				background: var(--bs-danger) !important;
			}
			&:after {
				border-color: white !important;
			}
		}
	}

	&.negative-checkbox input[type="checkbox"] {
		&:checked {
			+ label:after {
				content: "X";
				color: var(--bs-text-color);
				font-weight: bold;
				transform: none;
				width: $md-checkmark-size;
				height: $md-checkmark-size;

				border: none !important;
				font-size: 12px;
				font-family: cursive;
				padding-left: 4px;
			}
		}
	}

	&.colorful-border {
		input[type="checkbox"] + label:before {
			border-color: $md-checkbox-checked-color;
		}

		&.success-color input[type="checkbox"] + label:before {
			border-color: rgba(var(--bs-success-rgb), 0.3) !important;
		}

		&.danger-color input[type="checkbox"] + label:before {
			border-color: rgba(var(--bs-danger-rgb), 0.6) !important;
		}
	}
}

.btn-light {
	font-size: 1rem;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	/* padding: 0.4rem; */
	min-width: 105px;
	height: 36px;
	background: transparent;
	color: #486db5;
	opacity: 0.7;
	transition: background .2s;
	border: none;
	cursor: pointer;
	border-radius: 20px;
	&:hover{
		background: #d4e2ff4a;
	}
}

.vertical-separator {
	height: 100%;
	width: 0;
	border: solid 1px;
}

.simpleDataTable {
	td, th {
		padding: 10px;
		border-bottom: solid 1px rgba(0, 0, 0, 0.05);
		text-align: left;
	}

	td:first-child, th:first-child {
		padding-left: 0;
	}

	&Shrink {
		td, th {
			padding: 5px;
			border-bottom: none;
			text-align: left;
		}

		&:not(.middle) {
			td, th {
				vertical-align: top;
			}
		}
	}
}

.redborder-adv:hover {
	font-weight: 400 !important;
	color: #486db5 !important;
}

.iex { // internet explorer exceptions
	@for $i from 1 through 5 {
		.gap-#{$i}.flex-column:not(.flex-wrap), .gap-#{$i}[class^='flxClmn']:not(.flex-wrap) {
			> * {
				margin: calc(5px * #{$i}) 0;

				&:first-child {
					margin-top: 0;
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
		.gap-#{$i}:not(.flex-column):not([class^='flxClmn']):not(.flex-wrap) {
			> * {
				margin: 0 calc(5px * #{$i});

				&:first-child {
					margin-left: 0;
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}
		.gap-#{$i}.flex-wrap {
			> * {
				margin:calc(0.3rem * #{$i});
			}
		}
	}

	.md-checkbox {
		label {
			&:before {
				border-width: 0.15em !important;
			}
		}
	}
}
.t-bg-primary {
	background-color: var(--bg-primary);
}

.t-bg-secondary {
	background-color: var(--bg-secondary);
}

.t-color-primary {
	color: var(--color-primary);
}
.t-color-secondary {
	color: var(--gray2);
}
.t-color-danger {
	color: var(--danger);
}
.t-color-warning {
	color: var(--warning);
}
.t-color-success {
	color: var(--success);
}
